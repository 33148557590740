import styled from "styled-components";
import { Header } from "./Header";

const Content = styled.main`
  margin: 0 auto;
  margin-top: 40px;
  max-width: 930px;
  width: 100%;
`;

const Layout = ({ children }) => {
  return (
    <>
      {/* <Header /> */}
      <Content>{children}</Content>
    </>
  );
};

export default Layout;
